<template>
  <app-input-box
    :errors="cmpErrors"
    :clean="clean"
    :is-cleared="isCleared"
    :helper-text="helperText"
    :helper-text-type="helperTextType"
  >
    <input
      :name="name"
      :class="{
        filled: filled,
        'without-label': !label,
        'has-additional': hasAdditional,
        'has-loader': displayLoader,
        hover: inputHoverStyle
      }"
      v-bind="$attrs"
      ref="input"
      v-model="cmpValue"
      @input="update"
      @focus="focus"
      @click="cleanErrors"
      @blur="getBlur(clean, $event)"
      @mouseover="hoverInput()"
      @mouseout="unhoverInput()"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keyup', $event)"
    >

    <label
      v-if="label"
      @click="clickByLabel"
      @mouseover="hoverInput()"
      @mouseout="unhoverInput()"
    >
      {{ label }}
    </label>

    <div class="loader-wrapper" v-if="withLoader">
      <loader :react-to-ajax="withLoader" @running="onRunning" />
    </div>

    <div
      class="btn-additional"
      v-if="hasAdditional"
      @mouseover="hoverInput()"
      @mouseout="unhoverInput()"
    >
      <app-tooltip :text="tooltip" v-if="tooltip" />
      <slot name="additional" v-bind="self" />
    </div>
  </app-input-box>
</template>

<script>
  import inputMixin from '../../mixins/inputMixin'

  export default {
    mixins: [inputMixin],

    emits: ['update:modelValue', 'focus', 'keyup'],

    data () {
      return {
        displayLoader: false
      }
    },

    computed: {
      self () {
        return this
      },

      filled () {
        return !(this.cmpValue === undefined || this.cmpValue === null || this.cmpValue === '')
      },

      hasAdditional () {
        return (!!this.$slots.additional || this.tooltip) && !this.displayLoader
      }
    },

    watch: {
      modelValue (newValue) {
        if (newValue !== this.cmpValue) {
          this.cmpValue = newValue
          this.cleanErrors()
        }
      }
    },

    methods: {
      update () {
        this.$emit('update:modelValue', this.cmpValue)
      },

      focus () {
        this.$emit('focus')
      },

      clean () {
        this.cmpValue = null
        this.cleanErrors()
        this.update()
      },

      focused () {
        this.$refs.input.focus()
      },

      getInput () {
        return this.$refs.input
      },

      onRunning (data) {
        this.displayLoader = data
      }
    }
  }
</script>

<style lang="scss" scoped>

  $transition-dur: .2s;

  .input-box {
    width: 100%;

    &.with-error {
      input:not(:focus) {
        border-color: var(--red);
      }
    }

    &[type="password"] input {
      padding: 27px 40px 9px 12px;
    }

    &.bg-transparent input {
      background-color: transparent !important;
    }
  }

  label {
    position: absolute;
    left: 12px;
    top: 18px;
    color: var(--dark-grey);
    transition: all $transition-dur;
    cursor: text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 20px);

    @include mobile {
      top: 20px;
      font-size: 16px;
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    color: var(--black);
    outline: none;
    transition: all $transition-dur;
    cursor: text !important;
    box-shadow: none;
    background: var(--white);

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px rgba(#FFFFFF, 1) inset;
    }

    .input-box & {
      border: 1px solid var(--grey);
      border-radius: 10px;
      padding: 27px 12px 9px 12px;
      font-size: 20px;
      line-height: 120%;

      @include mobile {
        font-size: 16px;
        padding: 27px 12px 12px;
      }

      &:hover, &.hover:not(:focus) {
        border: 1px solid var(--dark-grey);
      }

      &::placeholder {
        color: transparent;

        @include mobile {
          font-size: 16px;
        }
      }

      &:focus {
        box-shadow: 0px 0px 0px 3px var(--grey);
        &::placeholder { color: var(--grey); }

        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px var(--white) inset;
        }

        &:hover {
          border-color: var(--grey);
        }
      }

      &:focus ~ label, &.filled ~ label {
        font-size: 13px;
        line-height: 125%;
        top: 10px;
      }

      &, &.filled {
        &:not(:focus) {
          background-color: var(--white);
          &:-webkit-autofill {
            box-shadow: 0 0 0 1000px var(--white) inset;
          }
        }

        &:disabled, &:read-only {
          background-color: var(--light-grey);
          border-color: transparent;
          color: var(--dark-grey);
        }

        &:disabled ~ label, &:read-only ~ label {
          color: var(--dark-grey);
        }

        &:invalid {
          border-color: var(--red);
        }

        &:invalid ~ .btn-clean {
          background-color: var(--red);
        }
      }

      &.has-additional, &.has-loader {
        padding: 27px 60px 9px 12px;

        @include mobile {
          padding: 27px 60px 12px 12px;
        }
      }

      &:disabled, &:read-only {
        border-color: transparent;
      }
    }

    .with-additional & {
      padding-right: 40px;
    }

    &.without-label {
      padding-top: 19px !important;
      padding-bottom: 19px !important;
    }
  }
</style>
